@import "styles/mixins/index";

.product-card {
  border: 1px solid var(--border-color-4);
  display: block;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  max-width: 335px;
  // overflow: hidden;
  padding: 27px 18px 18px 18px;
  min-height: 410px;
  @include normal-radius;
  // @include pointer;
  @include ease-in-out;
  @include breakpoint("max-md") {
    min-height: 430px;
  }
  @include breakpoint("max-sm") {
    border: none;
    padding: 28px;
    border: 1px solid var(--border-color-4);
    min-height: 390px;
    @media only screen and (max-width: 450px) {
      padding: 11px;
    }
  }
  .product-card-onsale-lable{
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    font-size: 12px;
    padding: 4px 15px 4px 4px;
    border-radius: 0 5px 5px 0;
    margin: 0;
    font-weight: 600;
    background-color: var(--add-to-cart-sale-bg-color);
    color: var(--add-to-cart-sale-font-color);
    &::before {
      display: inline-block;
      width: 0;
      height: 0; 
      border-style: solid;
      top: 0;
      border-width: 12px 12px 12px 0;
      position: absolute;
      content: "";
      right: -1px;
      border-color: transparent var(--bg-white) transparent transparent;
    }
  }
  &:hover {
    border: 1px solid var(--primary-color);
    @include breakpoint("max-sm") {
      // display: none;
      border-bottom: 1px solid var(--primary-color);
    }
  }
  .coupon-used-time-ribbon {
    position: absolute;
    right: 0;
    top: 0;
    @include breakpoint("max-sm") {
      top: 4px;
    }
    .coupon-used-time {
      background-color: var(--secondary-color);
      width: fit-content;
      padding: 17px 6px 16px 7px;
      margin: 2px 2px 15px -18px;
      position: relative;
      @include breakpoint("max-sm") {
        margin: 0 0 10px -2px;
      }
      p {
        color: var(--bg-white);
        @include heading-text-sm-normal;
        @include breakpoint("max-md") {
          font-size: 12px;
          line-height: 16px;
        }
        span {
          @include font-medium;
        }
      }
      &::before {
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        bottom: -8px;
        right: 8px;
        border-width: 14px 12px 14px 0;
        transform: rotate(90deg);
        position: absolute;
        content: "";

        border-color: transparent var(--bg-white) transparent transparent;
      }
    }
  }

  .product-card-image {
    @include flex-center;
    justify-content: map-get($layout, center);
    min-height: 137px;
    max-height: 137px;
    @include breakpoint("max-sm") {
      padding: 16px;
    }
    img {
      max-width: 170px;
      max-height: 170px;
      object-fit: cover;
      @include breakpoint("max-sm") {
        // min-width: 92px;
        // max-width: 92px;
        // max-height: 116px;
        // min-height: 116px;
        max-height: 105px;
        width: 100%;
      }
    }
  }
  .product-card-details {
    padding: map-get($pixel, md) * 3 + 4px 0 0 0;
    height: 50px;
    @include breakpoint("max-sm") {
      padding: 0;
    }
    .digital-coupon-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      margin: 0 0 map-get($pixel, md) -18px;
      @media (max-width: 1500px) {
        flex-wrap: wrap;
        .offer-price{
          margin-top: 8px;
        }
      }
      .offer-price{
        font-size: 9px;
        font-weight: 600;
      }
      .coupon-title{
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 2px 2px 8px 0 rgba(0,0,0,.2);
        color: #000;
        font-size: 13px;
        left: 0;
        right: 0;
        width: 100%;
        top: 80%;
        display: none;
        padding: 5px 10px;
        position: absolute;
        transition: opacity .3s;
        z-index: 99;
        word-wrap: break-word;

    box-shadow: 2px 2px 8px 0 rgba(0,0,0,.2);
    @media only screen and (max-width: 651px) {
      // min-width: 200px;
      word-wrap: break-word;
      text-wrap: wrap;
    }
  }
 
      .coupon-design {
        background-color: var(--coupon-bg-color);
        width: fit-content;
        padding: 6px 15px 6px 9px;
        // margin: 0 0 map-get($pixel, md) -18px;
        position: relative;
        @include breakpoint("max-sm") {
          margin: 0 0 10px -2px;
        }
        &:hover + .coupon-title{
          // opacity: 1; 
          display: block;
            }
        p {
          color: var(--coupon-text-color);
          @include heading-text-sm-normal;
          font-size: 13px;
          @include breakpoint("max-md") {
            font-size: 12px;
            line-height: 16px;
          }
          span {
            @include font-medium;
          }
        }
        &::before {
          display: inline-block;
          width: 0;
          height: 0; 
          border-style: solid;
          top: 0;
          border-width: 14px 12px 14px 0;
          position: absolute;
          content: "";
          right: -1px;
          border-color: transparent var(--bg-white) transparent transparent;
        }
      }
      // .coupon-used-time {
      //   white-space: nowrap;
      //   font-size: 14px;
      //   margin-right: -14px;
      // }
      &.hidden {
        display: none;
        min-height: 38px;
      }
    }

    .regular-price {
      padding: 0 0 map-get($pixel, md) - 2px 0;
      .regular-price1 {
        font-size: 20px;
        padding: 0 0 8px 0px;
      }
      p {
        @include text-sm;
        @include font-medium;
        color: var(--black-text-color);
        span {
          color: var(--primary-color);
          font-weight: 700;
        }
      }
      .hidden {
        display: none;
        min-height: 19px;
      }
      .max-h-38 {
        min-height: 38px;
      }
      .regular-price3 {
        font-size: 12px;
      }
      .regular-price4 {
        font-size: 12px;
      }
    }
    a {
      @include heading-4;
      color: var(--black-text-color);
      display: block;
      line-height: 22px;
      // min-height: 44px;
      @include ease-in-out;
      margin: 0 0 map-get($pixel, sm) + 3px 0;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      min-height: 44px;
      -webkit-box-orient: vertical;
      overflow: hidden;
      &:hover {
        color: var(--secondary-color);
      }
    }
    .button-group-alignment {
      @include flex-between;
      padding-top: 10px;
      
      .button-group-section {
        align-items: center;
        display: flex;
        justify-content: space-between;
        position: absolute;
        top: auto;
        right: 0;
        left: 0;
        bottom: 15px;
        padding: 0 15px;
        @include breakpoint("max-sm") {
          position: unset;
          padding: 0;
          width: 100%;
        }
        .add-to-cart{
          width: 100%;
        }
        .button-group {
          border: 1px solid var(--border-color-5);
          @include normal-radius;
          @include flex-center;
          @include pointer;
          width: fit-content;
          .action-button,
          .counter {
            width: 24px;
            height: 23px;
            @include flex-center;
            justify-content: map-get($layout, center);
          }
          .action-button {
            color: var(--gray-text-color);
            @include ease-in-out;
            &:hover {
              background-color: var(--primary-color);
              color: var(--bg-white);
            }
            i {
              font-size: 12px;
            }
          }
          .action-button-plus {
            width: 29px;
            height: 23px;
            @include flex-center;
            color: var(--gray-text-color);
            justify-content: map-get($layout, center);
            @include ease-in-out;
            &:hover {
              background-color: var(--primary-color);
              color: var(--bg-white);
            }
            i {
              font-size: 12px;
            }
          }
          .counter {
            background-color: var(--border-color);
            @include heading-text-sm;
            color: var(--black-text-color);
          }

          @include breakpoint("max-sm") {
            margin: 0 2px;
          }
        }

        .add-list-button {
          padding-right: 0px;
          margin-left: 10px;
          i {
            cursor: pointer;
            font-size: 36px;
            @include breakpoint("max-sm") {
              font-size: 25px;
            }
          }
          button {
            border-radius: 4px;
          }

          @include breakpoint("max-sm") {
            margin: 0 2px;
          }
        }
        .add-to-list-show {
          overflow: visible;
        }
        .new-list-input-dropdown {
          bottom: 0;
          position: absolute;
          top: -81%;
          right: 0;
          left: 0;
        }
        .add-to-list-dropdown {
          right: 0px;
          top: auto;
          bottom: 40px;
          @include breakpoint("max-sm") {
            right: 5px;
          }
        }
      }
      
    }
    .final-price {
      padding: 0 0 map-get($pixel, md) - 2px 0;
      p {
        @include text-sm-normal;
        color: var(--black-text-color);
        font-size: 20px;
        @include font-medium;
        span {
          color: var(--primary-color);
          font-weight: 800;
          margin: 0 10px 0 0;
          @media only screen and (max-width: 450px) {
            display: inline-block;
          } 
        }
        .price1 {
          font-size: 15px;
          font-weight: 400;
          text-decoration: line-through;
          color: #7a7a7a;
          vertical-align: bottom;
        }
      }
      &.hidden {
        display: none;
        min-height: 19px;
      }
    }
    .add-to-cart {
      width: 100%; 
      button {
        padding: map-get($pixel, sm) + 7px map-get($pixel, md) + 6px;
        background-color: var(--add-to-card-bg-color);
        color: var(--add-to-card-font-color);
        width: 100%;
        justify-content: space-between;
        @include text-xs-semi-bold;
        border: none;
        @include normal-radius;
        @include flex-center;
        @include pointer;
        @include ease-in-out;
        &:hover:not([disabled]) {
          background-color: var(--add-to-card-bg-color);
          color: var(--add-to-card-font-color);
          svg {
            stroke: var(--add-to-card-font-color);
            g {
              path {
                fill: var(--add-to-card-font-color);
              }
            }
          }
        } 
        span {
          padding-left: 4px;
          padding-right: 4px;
          color: var(--add-to-card-font-color);
          font-family: var(--heading-font-family), var(--default-font-family);
        }
        svg {
          stroke: var(--add-to-card-font-color);
          // @include ease-in-out;
          g {
            path {
              fill: var(--add-to-card-font-color);
            }
          }
        }
        &:disabled {
          cursor: default;
        }
      }
      .add-to-cart-display-block {
        display: block;
        .out-of-stock-icon{
          display: none;
        }
      }
      @include breakpoint("max-sm") {
        margin: 0 2px;
      }
    }
    .add-to-cart.add-outof-stock-btn {
      button {
        background-color: var(--add-to-cart-outofstock-bg-color);
        color: var(--add-to-cart-outofstock-font-color);
        cursor: no-drop;
        &:hover:not([disabled]) {
          background-color: var(--add-to-cart-outofstock-bg-color);
          color: var(--add-to-cart-outofstock-font-color);
          svg {
            stroke: var(--add-to-cart-outofstock-font-color);
            g {
              path {
                fill: var(--add-to-cart-outofstock-font-color);
              }
            }
          }
        } 
        span {
          color: var(--add-to-cart-outofstock-font-color);
        }
        svg {
          stroke: var(--add-to-cart-outofstock-font-color);
          // @include ease-in-out;
          g {
            path {
              fill: var(--add-to-cart-outofstock-font-color);
            }
          }
        }
            &:disabled {
              cursor: no-drop;
              opacity: 0.5;
              .add-to-cart-icon{
                display: none;
              }
              .out-of-stock-icon{
                display: inline-block;
                vertical-align: baseline;
              }
            }
           
      }
    }
    .button-disable {
      pointer-events: none;
      opacity: 0.5;
    }
    
    .price {
      @include flex-center;
      padding: 0 0 map-get($pixel, md) * 2 + 8px 0;
      h3 {
        @include text-bold;
        color: var(--color);
        padding-right: 21px;
      }
      del {
        @include text-sm-semi-bold;
        font-size: 13px;
        color: var(--gray-8);
      }
    }
    .star-text-alignment {
      @include flex-center;
      padding: 0 0 map-get($pixel, sm) + 3px 0;
      .rating-text {
        span {
          @include text-xs-normal;
          color: var(--gray-7);
        }
      }
      .star-alignment {
        @include flex-center;
        padding-right: 10px;
        i:last-child {
          margin-right: 0px;
        }
        i {
          color: var(--secondary-color);
          margin-right: 3px;
          @include pointer;
          font-size: 12px;
        }
      }
    }
    h3 {
      @include text-sm;
      padding: 0 0 map-get($pixel, sm) + 3px 0;
      display: block;
      color: var(--color);
    }

    .d-none {
      display: none;
    }
  }

  
}
