.main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid black;
  width: 60%;
  margin: 0 auto;
  background-color: lightgray;

  .button-container {
    background-color: gray;
    width: 100%;
    // border: 2px solid black;

    span {
      padding: 2rem;
    }
  }
}

.pdf-container {
  overflow: auto;
  min-height: 50rem;

  .pdf-inner {
    transform: scale(1);
  }
}
